



























































































import { popoStore } from '@/stores/popo-store'
import { IReactionDisposer, reaction } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'decorate-button': () => import('@/modules/popo/components/decorate-button.vue'),
    'nft-gallery-tab': () => import('@/modules/popo/components/decorates/nft-gallery-tab.vue'),
  },
})
export default class extends Vue {
  popoStore = popoStore
  tab = null

  _diposers: IReactionDisposer[] = []

  switchMint() {
    if (popoStore.havePendingNFTs) popoStore.changeOpenRemindClaimPopoDialog(true)
    else popoStore.changeOpenConfirmMintingDialog(true)
  }

  created() {
    this._diposers = [
      reaction(
        () => popoStore.openPopoDecorateDialog,
        (val) => {
          if (val) {
            this.tab = null
          }
        }
      ),
    ]
  }

  destroyed() {
    this._diposers.forEach((d) => d())
  }
}
